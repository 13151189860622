import { Slide } from "../../hooks/useFeedback"
import { FC, RefObject, createRef, useEffect, useRef, useState } from "react"
import { useSwipeable } from "react-swipeable"

type CarouselProps = {
	slides?: Array<Slide>
}

const Carousel: FC<CarouselProps> = ({ slides }) => {
	const carouselRef = useRef<HTMLDivElement>(null)
	const [slidesRef, setSlidesRef] = useState<Array<RefObject<HTMLDivElement>>>(
		[]
	)
	const [selected, setSelected] = useState(0)
	const { ref, onMouseDown } = useSwipeable({
		onSwipedLeft: () =>
			setSelected((previous) =>
				slides?.length === previous + 1 ? 0 : previous + 1
			),
		onSwipedRight: () =>
			setSelected((previous) =>
				previous === 0 ? (slides?.length ? slides.length - 1 : 0) : previous - 1
			),
		trackMouse: true
	})

	useEffect(() => {
		if (slidesRef[selected]?.current?.clientWidth && carouselRef.current) {
			const scrollOffset = selected * slidesRef[selected].current!.clientWidth

			if (carouselRef.current.scrollLeft !== scrollOffset) {
				carouselRef.current.scrollTo({
					left: scrollOffset
				})
			}
		}
	}, [selected, slidesRef])

	useEffect(() => {
		if (slides)
			setSlidesRef((previous) =>
				Array.from({ length: slides.length }).map(
					(_, i) => previous[i] || createRef()
				)
			)
	}, [slides])

	useEffect(() => {
		ref(carouselRef.current)
	}, [carouselRef, ref])

	return (
		<div className="relative h-full">
			<div
				data-testid="carousel"
				ref={carouselRef}
				onMouseDown={onMouseDown}
				className="carousel absolute inset-0 h-full">
				{slides?.map((s, i) => (
					<div
						ref={slidesRef[i]}
						key={s.id}
						className="carousel-item relative h-full w-full overflow-hidden">
						<img
							className="absolute h-full w-full object-cover brightness-50"
							src={s.photo}
							alt={`slide-${s.id}`}
						/>
						<div className="absolute inset-0 flex items-center justify-center">
							<p className="ml-0 max-w-md text-center text-3xl text-white md:ml-96 md:text-start">
								{s.theme}
								<span className="description block break-keep text-white">
									{s.description}
								</span>
							</p>
						</div>
					</div>
				))}
			</div>
			<div className="absolute inset-x-0 bottom-3 flex justify-center gap-x-4">
				{slides?.map((s, i) => (
					<span
						key={s.id}
						data-testid="slide-button"
						onClick={() => setSelected(i)}
						className={`inline-block h-2.5 w-2.5 rounded-full ${
							selected === i ? "bg-teal-400" : "bg-white"
						}`}
					/>
				))}
			</div>
		</div>
	)
}

export default Carousel
