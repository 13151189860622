import { Database } from "../types/supabase"
import { SupabaseClient, createClient } from "@supabase/supabase-js"

export const url = "https://sxqlzlelwdslochdutgr.supabase.co"
const apiKey =
	"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InN4cWx6bGVsd2RzbG9jaGR1dGdyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDYxOTE4MDQsImV4cCI6MjAyMTc2NzgwNH0.7qWZQv_5ht-QMKtCSSMbyUKbKbQ57yh8XXoQWmaar5g"

let supabaseClient: SupabaseClient | undefined

function lazyClient(): SupabaseClient {
	if (supabaseClient) return supabaseClient
	const client = createClient<Database>(url, apiKey)
	supabaseClient = client
	return client
}

export default lazyClient
