import { Photo } from "../../types/Gallery"
import { FC } from "react"
import { twJoin } from "tailwind-merge"

type GalleryPhotosProps = {
	photos?: Array<Photo>
	skeletonSize?: number
}

const GalleryPhotos: FC<GalleryPhotosProps> = ({ photos, skeletonSize }) => {
	const colByIndex = (index: number, photos?: Array<Photo>): string => {
		const length = photos?.length ?? 0

		return (length + index + 1) % 6 === 1 || !((length + index + 1) % 6)
			? "col-span-2"
			: "col-span-1"
	}

	return (
		<div className="flex grid-cols-4 flex-col content-center gap-5 md:grid lg:gap-10">
			{photos?.length === 0
				? "Doesn`t have photos"
				: photos?.map(({ id, data }, i) => (
						<img
							key={id}
							src={data}
							alt=""
							className={twJoin(
								"h-52 w-full object-cover md:h-40 lg:h-52",
								colByIndex(i, photos)
							)}
						/>
				  ))}
			{skeletonSize &&
				Array.from({ length: skeletonSize }).map((_, i) => (
					<span
						data-testid="photo-skeleton"
						key={i}
						className={twJoin("skeleton h-52", colByIndex(i, photos))}
					/>
				))}
		</div>
	)
}

export default GalleryPhotos
