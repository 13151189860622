import { Category, Photo } from "../types/Gallery"
import client from "./supabaseClient"
import { PostgrestError } from "@supabase/supabase-js"

export const getCategories = async (): Promise<
	PostgrestError | Omit<Category, "photos">[] | undefined
> => {
	const { error, data } = await client().from("gallery-category").select()

	if (error) {
		return error
	}

	return data
}

export const getPhotosByCategory = async (
	bucket: string,
	limit?: number,
	offset?: number
): Promise<Error | Photo[] | undefined> => {
	const { data, error } = await client()
		.storage.from("gallery")
		.list(bucket, {
			limit,
			offset,
			sortBy: { column: "name", order: "asc" }
		})

	if (error) return error

	if (data) {
		const photos = await Promise.all(
			data.map<Promise<Photo>>(async (p, i) => ({
				id: `${bucket}-${i + (offset ?? 0)}`,
				data: await client()
					.storage.from("gallery")
					.getPublicUrl(`${bucket}/${p.name}`).data.publicUrl
			}))
		)
		return photos
	}
}
