import { ButtonHTMLAttributes, FC } from "react"
import { twMerge } from "tailwind-merge"

type ButtonProp = ButtonHTMLAttributes<HTMLButtonElement> & {
	loading?: boolean
	skeleton?: boolean
}

const Button: FC<ButtonProp> = ({
	loading,
	children,
	className,
	skeleton,
	...props
}) => {
	if (skeleton)
		return (
			<div
				data-testid="button-skeleton"
				className={twMerge("skeleton h-12 w-56", className)}
			/>
		)

	return (
		<button
			{...props}
			className={twMerge("btn w-56 py-2 font-normal", className)}>
			{loading && (
				<span
					data-testid="button-spinner"
					className={`loading loading-spinner loading-sm -ml-7 align-middle text-teal-400`}
				/>
			)}
			{children}
		</button>
	)
}

export default Button
