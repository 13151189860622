import { FC } from "react"
import useGallery from "../../hooks/useGallery"
import Button from "../Button/Button"
import GalleryCategories from "./GalleryCategories"
import GalleryPhotos from "./GalleryPhotos"

const Gallery: FC = () => {
	const {
		categories,
		loading,
		initiating,
		morePhotos,
		setSelectedCategory,
		selectedCategory,
		skeletonSize
	} = useGallery(9)
	return (
		<div className="flex w-full flex-col gap-5 px-8">
			<GalleryCategories
				clickable={!initiating}
				categories={categories}
				setSelected={setSelectedCategory}
				selectedId={selectedCategory?.id}
			/>
			<GalleryPhotos
				photos={selectedCategory?.photos}
				skeletonSize={skeletonSize}
			/>
			<Button	
				type="button"
				className="self-center"
				skeleton={!selectedCategory?.photos}
				loading={loading}
				disabled={loading || !selectedCategory?.canMorePhotos}
				onClick={() => morePhotos(3)}>
				{selectedCategory?.canMorePhotos ? "Show more" : "This`s all"}
			</Button>
		</div>
	)
}

export default Gallery
