import reducers from "./slices"
import { configureStore } from "@reduxjs/toolkit"

const store = configureStore({
	reducer: reducers,
	devTools: true
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
