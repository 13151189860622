import useFeedback from "../../hooks/useFeedback"
import Button from "../Button/Button"
import Input from "../Input/Input"
import { zodResolver } from "@hookform/resolvers/zod"
import { FC, useEffect } from "react"
import { useForm } from "react-hook-form"
import { isAlpha, isMobilePhone } from "validator"
import { z } from "zod"

const schema = z.object({
	feedback: z
		.string()
		.min(1, "Feedback must contain at least 1 character")
		.max(400, "Feedback must contain at most 400 characters"),
	name: z
		.string()
		.min(1, "Name must contain at least 1 character")
		.max(25, "Name must contain at most 25 characters")
		.refine(isAlpha, "Name should consist of letters"),
	phone: z.string().refine(
		(phone) =>
			isMobilePhone(phone, "any", {
				strictMode: true
			}),
		"Please enter your phone. Example: +380947100983"
	)
})

type ValidationSchema = z.infer<typeof schema>

const FeedbackForm: FC = () => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isValid }
	} = useForm<ValidationSchema>({
		mode: "onChange",
		resolver: zodResolver(schema)
	})
	const { handler, loading, responseOk, clear } = useFeedback()

	useEffect(() => {
		if (responseOk)
			setTimeout(() => {
				clear()
				reset()
			}, 2500)
	}, [responseOk])
	return (
		<form
			onSubmit={handleSubmit(handler)}
			className="form-control h-96 w-full max-w-sm px-8 sm:px-0">
			<Input
				className="h-9 w-full"
				placeholder="Name"
				error={errors.name}
				{...register("name")}
			/>
			<Input
				className="h-9 w-full"
				placeholder="Phone"
				error={errors.phone}
				{...register("phone")}
			/>
			<Input
				textarea
				className="h-32 w-full resize-none align-top"
				placeholder="Leave a message and we will contact you shortly"
				error={errors.feedback}
				{...register("feedback")}
			/>
			<Button
				loading={loading}
				className="mx-auto mt-5 lg:mx-0"
				type="submit"
				disabled={!isValid || loading || responseOk}>
				Send
			</Button>
			{responseOk && <p className="text-info">Thank`s for your feedback.</p>}
		</form>
	)
}

export default FeedbackForm
