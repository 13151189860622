import { Category } from "../../types/Gallery"
import { FC } from "react"
import { twJoin } from "tailwind-merge"

type GalleryCategoriesProps = {
	categories?: Array<Category>
	selectedId?: number
	clickable?: boolean
	setSelected: (category: Category) => void
}

const GalleryCategories: FC<GalleryCategoriesProps> = ({
	categories,
	selectedId,
	clickable,
	setSelected
}) => {
	return (
		<span className=" space-x-5">
			{categories ? (
				categories.map((c) => (
					<span
						data-testid={
							selectedId === c.id
								? "selected-gallery-category"
								: "gallery-category"
						}
						key={c.id}
						className={twJoin(
							"uppercase hover:cursor-pointer",
							selectedId === c.id && "text-teal-400"
						)}
						onClick={() => clickable && setSelected(c)}>
						{c.name}
					</span>
				))
			) : (
				<div
					data-testid="category-skeleton"
					className="skeleton h-6 w-24 rounded"
				/>
			)}
		</span>
	)
}

export default GalleryCategories
