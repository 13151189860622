import { FC } from "react"

const AboutSchoolSection: FC = () => {
	return (
		<section id="about" className="mx-8 place-items-center gap-y-20 md:grid">
			<div className="title">
				ABOUT THE SCHOOL, ABOUT SURFING, ABOUT THE CANARIES
			</div>
			<div className="flex flex-col items-center md:flex-row md:items-baseline md:gap-x-20 ">
				<p className="description max-w-sm">
					Our surf school teachers are among the best surfers in the Canary
					Islands. Among them is the European longboard champion, who will
					happily share his experience with both beginners and more experienced
					surfers. All members of the teaching staff are certified members of
					the Canary Islands Surfing Federation. Some of them know Ukrainian,
					which definitely positions us as a Ukrainian surf school abroad!
					<br />
					<br />
					This is the best place in Spain to learn to surf. In addition to the
					huge Atlantic waves that come here in winter and autumn, Fuerteventura
					also boasts many shallow, calm lagoons, perfect for teaching beginner
					surfers.
					<br />
					<br />
					Surfing in the Canary Islands is available all year round - there are
					always waves and suitable weather here. Moreover, both a real pro and
					a beginner can find a wave that is suitable for the level. It is no
					coincidence that it is believed that this particular place is best
					suited for training; here it is possible to move to the next level
					without difficulties.
					<br />
					<br />
					While listing the advantages, one cannot ignore the fact that
					Fuerteventura’s beaches are the longest in the Canaries. As a result,
					it is impossible to imagine them overcrowded with tourists. You will
					only come across those who, like you, will be interested in one goal -
					learning to surf.
				</p>
				<p className="description max-w-sm ">
					For now, do you just want to understand what surfing is, or have you
					already experienced all the delights of riding the waves, but want to
					raise your level? Our surf school can offer training for any skill
					level. <br />
					<br />
					<br className="hidden md:inline-block" />
					<br className="hidden md:inline-block" />
					<br className="hidden md:inline-block" />
					<br className="hidden md:inline-block" />
					<br className="hidden md:inline-block" />
					Four hours of exciting activities a day will help you conquer the
					waves with confidence and feel like a real surfer. <br />
					<br />
					<br className="hidden md:inline-block" />
					<br className="hidden md:inline-block" />
					<br className="hidden md:inline-block" />
					The main advantages of our Ukrainian school are the high quality of
					teaching and organization of classes, safety (all our clients are
					insured by Allianz), excellent equipment (provided for the entire
					course of study), including soft, safe boards for beginners. <br />
					First, we determine the level of skating and physical fitness and only
					then we draw up a training program. And we can assure you that after
					the training is over, you will not only get a lot of great sensations,
					but this level will also rise to a new level.
				</p>
			</div>
		</section>
	)
}

export default AboutSchoolSection
