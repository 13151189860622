import { FC } from "react"

const QuoteSection: FC = () => {
	return (
		<section className="bg-base-200/70 py-10 ">
			<p className="text-center text-2xl dark:text-amber-500 md:text-3xl">
				«When in doubt, go for a ride.»
				<span className="description block text-stone-400 dark:text-teal-400 md:indent-96">
					Nat Young, first World Surfing Champion
				</span>
			</p>
		</section>
	)
}

export default QuoteSection
