import client from "../api/supabaseClient"
import { Database } from "../types/supabase"
import { useState } from "react"

export type Slide = Database["public"]["Tables"]["blog"]["Row"]

type FormInputs = {
	name: string
	phone: string
	feedback: string
}

export type ReturnFeedbackType = {
	handler: (data: FormInputs) => void
	clear: () => void
	loading?: boolean
	responseOk?: boolean
}

function useFeedback(): ReturnFeedbackType {
	const [state, setState] =
		useState<Omit<ReturnFeedbackType, "handler" | "clear">>()

	const handler = async (data: FormInputs): Promise<void> => {
		setState({ loading: true })

		const { status } = await client().from("feedback").insert({
			message: data.feedback,
			phone: data.phone,
			user_name: data.name
		})

		setState({ loading: false, responseOk: status === 201 })
	}

	const clear = (): void => {
		setState({})
	}

	return {
		...state,
		handler,
		clear
	}
}

export default useFeedback
