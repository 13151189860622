import { getCategories, getPhotosByCategory } from "../api/supabaseApi"
import { Category, Photo } from "../types/Gallery"
import { PostgrestError } from "@supabase/supabase-js"
import { useEffect, useState } from "react"

type ReturnGalleryType = {
	initiating?: boolean
	loading?: boolean
	categories?: Category[]
	selectedCategory?: Category
	error?: PostgrestError | Error
	skeletonSize?: number
	setSelectedCategory: (category: Category) => void
	morePhotos: (limit: number) => void
}

type HookState = Omit<ReturnGalleryType, "morePhotos" | "setSelectedCategory">

function useGallery(limit: number): ReturnGalleryType {
	const [state, setState] = useState<HookState>({
		skeletonSize: limit,
		loading: false,
		initiating: true
	})

	const insertPhotosInSelected = (
		category: Category,
		photos: Photo[],
		canMorePhotos?: boolean
	): void => {
		setState((prev) => ({
			skeletonSize: undefined,
			loading: false,
			categories: prev.categories?.map((c) => {
				if (c.id === category.id) {
					return {
						...c,
						photos: [...(c.photos ?? []), ...photos],
						canMorePhotos: canMorePhotos ?? true
					}
				}

				return c
			}),
			selectedCategory: prev.selectedCategory && {
				...prev.selectedCategory,
				photos: [...(prev.selectedCategory.photos ?? []), ...photos],
				canMorePhotos: canMorePhotos ?? true
			}
		}))
	}

	const setSelectedCategory = async (category: Category): Promise<void> => {
		if (category.photos && category.photos.length > 0) {
			setState((prev) => ({ ...prev, selectedCategory: category }))
		} else {
			setState((prev) => ({
				...prev,
				skeletonSize: limit,
				selectedCategory: category
			}))

			const photosResult = await getPhotosByCategory(category.bucket, limit)

			if (Array.isArray(photosResult)) {
				insertPhotosInSelected(category, photosResult)
			} else {
				setState((prev) => ({
					...prev,
					error: photosResult
				}))
			}
		}
	}

	const morePhotos = async (photosCount: number): Promise<void> => {
		setState((prev) => ({ ...prev, skeletonSize: photosCount, loading: true }))
		const selected = state.selectedCategory

		if (selected) {
			const photosResult = await getPhotosByCategory(
				selected.bucket,
				photosCount,
				selected.photos?.length
			)

			if (Array.isArray(photosResult)) {
				insertPhotosInSelected(selected, photosResult, photosResult.length > 0)
			} else {
				setState((prev) => ({
					...prev,
					error: photosResult
				}))
			}
		}
	}

	const fetcher = async (photosCount: number): Promise<void> => {
		setState({ skeletonSize: photosCount })
		const galleriesResult = await getCategories()

		if (Array.isArray(galleriesResult)) {
			const newState: HookState = {
				categories: galleriesResult,
				selectedCategory: galleriesResult[0]
			}
			setState((prev) => ({ ...prev, ...newState }))

			const photosResult = await getPhotosByCategory(
				galleriesResult[0].bucket,
				photosCount
			)
			if (Array.isArray(photosResult)) {
				insertPhotosInSelected(galleriesResult[0], photosResult)
			} else {
				setState({
					error: photosResult
				})
			}
		} else {
			setState({
				error: galleriesResult
			})
		}
	}

	useEffect(() => {
		fetcher(limit).then(() =>
			setState((prev) => ({ ...prev, initiating: false }))
		)
	}, [])

	return {
		...state,
		setSelectedCategory,
		morePhotos
	}
}

export default useGallery
