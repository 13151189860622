import Logo from "../assets/logo.svg"
import Surfing from "../assets/surfing-wawe.png"
import ThemeSwitcher from "../components/ThemeSwitcher/ThemeSwitcher"
import { FC } from "react"

const IntroduceSection: FC = () => {
	const linkClassName =
		"decoration-teal-400 underline-offset-8 hover:underline hidden md:block"

	return (
		<section
			className="flex h-full w-full flex-col bg-cover"
			style={{
				backgroundImage: `url('${Surfing}')`
			}}>
			<nav className="fixed z-10 flex h-20 w-full items-center justify-around bg-gradient-to-b from-[#01353E] text-white backdrop-blur-sm">
				<img src={Logo} className="h-10 w-10" alt="logo" />
				<span className="flex gap-x-14">
					<a href="#about" className={linkClassName}>
						About us
					</a>
					<a href="#services" className={linkClassName}>
						Services
					</a>
					<a href="#price" className={linkClassName}>
						Price
					</a>
					<a href="#team" className={linkClassName}>
						Team
					</a>
					<a href="#contacts" className={linkClassName}>
						Contacts
					</a>
				</span>
				<ThemeSwitcher />
			</nav>
			<p className="grid grow place-content-center text-center text-xs leading-tight text-[#01353E] lg:text-base">
				<span className="block font-raleway text-4xl font-700 uppercase sm:text-5xl md:text-6xl lg:text-8xl xl:text-9xl">
					wawe
				</span>
				Surf school in the Canary Islands
			</p>
		</section>
	)
}

export default IntroduceSection
