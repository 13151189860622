import FeedbackForm from "../components/FeedbackForm/FeedbackForm"
import { FC } from "react"
import { Link } from "react-router-dom"

const Feedback: FC = () => {
	return (
		<section id="contacts" className="space-y-28">
			<p className="title">Feedback</p>
			<div className="flex flex-col items-center justify-center gap-7 lg:flex-row">
				<FeedbackForm />
				<p className="w-full self-start justify-self-start bg-base-200/70 p-12 text-center dark:text-white lg:w-[600px]  lg:text-start">
					Have a question?
					<br />
					You can contact us:
					<br />
					<br />
					WhatsApp, Viber, Telegram
					<br />
					+3 467 486-37-88
					<br />
					<br />
					Avenida grandes playas 79, Corralejo la Oliva Fuerteventura, Spain.
					35660
				</p>
			</div>
			<div className="flex justify-center gap-5 uppercase">
				<Link
					target="_blank"
					className="hover:text-teal-400 active:text-teal-600"
					to="https://www.instagram.com/">
					<span className="icon-[bi--instagram] h-10 w-10" />
				</Link>
				<Link
					target="_blank"
					className="hover:text-teal-400 active:text-teal-600"
					to="https://www.facebook.com/">
					<span className="icon-[bi--facebook] h-10 w-10" />
				</Link>
				<Link
					target="_blank"
					className="hover:text-teal-400 active:text-teal-600"
					to="https://www.youtube.com/">
					<span className="icon-[bi--youtube] h-10 w-10" />
				</Link>
			</div>
		</section>
	)
}

export default Feedback
