import Airplane from "../assets/airplane.svg"
import SunSee from "../assets/sun_see.svg"
import Surfing from "../assets/surfing.svg"
import Wawe from "../assets/wawe.svg"
import { FC } from "react"

const AdvantagesSchoolSection: FC = () => {
	return (
		<section
			id="services"
			className="mx-8 grid place-content-center md:gap-y-28 ">
			<div className="title">AT SCHOOL YOU WILL GET</div>
			<div className="inline-block space-y-20">
				<div className="flex flex-col items-center justify-center gap-x-16 gap-y-8 md:flex-row ">
					<div className="space-y-4 sm:max-w-[350px]">
						<div className="flex h-16 place-content-center place-items-center gap-x-3">
							<img src={SunSee} alt="sun and see" />
							<p>ACCOMMODATION</p>
						</div>
						<p className="description">
							Our surf school is located 100 m from the ocean coast. It features
							an outdoor pool and free Wi-Fi. The upper terrace offers ocean
							views. Distance to city center 15 min.
						</p>
					</div>
					<div className="space-y-4 sm:max-w-[350px]">
						<div className="flex h-16 place-content-center place-items-center gap-x-3">
							<img src={Airplane} alt="sun and see" />
							<p>
								FLIGHT AND
								<br />
								INSURANCE
							</p>
						</div>
						<p className="description">
							All program participants are insured by Allianz insurance company.
							Flights from Tenerife to Lanzarote and back are included in the
							price of the program
						</p>
					</div>
					<div className="space-y-4 sm:max-w-[350px]">
						<div className="flex h-16 place-content-center place-items-center gap-x-3">
							<img src={Surfing} alt="sun and see" />
							<p className="">
								TRAINING AND
								<br />
								EQUIPMENT
							</p>
						</div>
						<p className="description">
							All equipment and supplies are from well-known manufacturers and
							are provided for the entire course of the program. The training
							process begins with determining your skiing level
						</p>
					</div>
				</div>
				<div className="flex flex-col items-center gap-y-5 lg:flex-row">
					<div className="w-full space-y-3.5 md:w-[500px]">
						<span>
							<img
								src={Wawe}
								className="inline-block w-[65%]"
								alt="short wawe"
							/>
							<span className="ml-3">85%</span>
							<p className="description">We came for the second time</p>
						</span>
						<div>
							<img
								className="inline-block w-[75%]"
								src={Wawe}
								alt="middle wawe"
							/>
							<span className="ml-3">95%</span>
							<p className="description">Learned to ride</p>
						</div>
						<div>
							<img
								className="inline-block w-[79%]"
								src={Wawe}
								alt="long wawe"
							/>
							<span className="ml-3">99%</span>
							<p className="description">Satisfied clients</p>
						</div>
					</div>
					<p className="description md:max-w-[600px]">
						A huge advantage of our school is its own teaching methodology. We
						have developed our own style of teaching surfing, which allows
						beginners to get on the board as quickly as possible.
						<br />
						<br />
						<br />
						It was our methodology and our teachers that allowed us to pass the
						certification of the Canary Islands Surfing Federation - FEDERACIÓN
						CANARIA DE SURF. This means that our school meets all international
						standards. Recertification is carried out annually and this allows
						us to always strive to improve training.
					</p>
				</div>
			</div>
		</section>
	)
}

export default AdvantagesSchoolSection
