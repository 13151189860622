import Gallery from "../components/Gallery/Gallery"
import { FC } from "react"

const GallerySection: FC = () => {
	return (
		<section className="flex flex-col items-center gap-8">
			<div className="title">Gallery</div>
			<Gallery />
		</section>
	)
}

export default GallerySection
