import { setTheme, themeSelector } from "../../slices/theme"
import { FC } from "react"
import { useDispatch, useSelector } from "react-redux"

const ThemeSwitcher: FC = () => {
	const theme = useSelector(themeSelector)
	const dispatch = useDispatch()

	return theme === "light" ? (
		<span
			data-testid="moon"
			onClick={() => dispatch(setTheme("dark"))}
			className="icon-[bi--moon-fill] h-8 w-8 text-slate-500"
		/>
	) : (
		<span
			data-testid="sun"
			onClick={() => dispatch(setTheme("light"))}
			className="icon-[bi--sun-fill] h-8 w-8 text-yellow-500"
		/>
	)
}

export default ThemeSwitcher
