import { RootState } from "../store"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

type Theme = "dark" | "light"

const initialState = (localStorage.getItem("theme") as Theme) || "light"

const themeSlice = createSlice({
	name: "theme",
	initialState: initialState,
	reducers: {
		setTheme: (state, action: PayloadAction<Theme>) => {
			localStorage.setItem("theme", action.payload)
			return action.payload
		}
	}
})

export const themeSelector = (state: RootState): Theme => state.theme
export const { setTheme } = themeSlice.actions
export default themeSlice.reducer
